import React from "react";
import { useInView } from "react-intersection-observer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

//Context
import { NavbarContext } from "./context/NavbarContext";

//Pages
import HomeScreen from "./pages/Home/HomeScreen";
import ThanksScreen from "./pages/ThanksScreen/ThanksScreen";

const App = () => {
  const { ref: benefits, inView: benefitInView } = useInView({
    rootMargin: "-20px 0px -20px 0px",
  });
  const { ref: commonZones, inView: commonZonesInView } = useInView({
    rootMargin: "-20px 0px -20px 0px",
  });
  const { ref: types, inView: typesInView } = useInView({
    rootMargin: "-20px 0px -20px 0px",
  });
  const { ref: location, inView: locationInView } = useInView({
    rootMargin: "-20px 0px -20px 0px",
  });
  const { ref: funding, inView: fundingInView } = useInView({
    rootMargin: "-20px 0px -20px 0px",
  });
  const { ref: home, inView: homeInView } = useInView();

  return (
    <NavbarContext.Provider
      value={{
        benefits,
        commonZones,
        types,
        location,
        funding,
        home,
        benefitInView,
        commonZonesInView,
        typesInView,
        locationInView,
        fundingInView,
        homeInView,
      }}
    >
      <Router>
        <Routes>
          <Route exact path="*" element={<HomeScreen />} />
          <Route exact path="/agradecimientos" element={<ThanksScreen/>} />
        </Routes>
      </Router>
    </NavbarContext.Provider>
  );
};

export default App;
